<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="phone"
        width="200"
        placeholder="请输入手机号"
      ></el-input>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderId"
          label="订单编号"
          align="center"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="phoneArea"
          label="区号"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="130">
        </el-table-column>
        <el-table-column
          prop="contacts"
          label="收货人"
          align="center"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="收货人手机号"
          width="130"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="收货地址" width="240">
        </el-table-column>
        <el-table-column prop="oldBoxId" label="旧趣宝盒ID" width="220">
        </el-table-column>
        <el-table-column prop="newBoxId" label="新趣宝盒ID" width="220">
        </el-table-column>
        <el-table-column prop="orderStatus" label="交易状态" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.orderStatus }}</div>
            <!-- <el-button size="mini" type="text" @click="goDetail(scope.row)"
            >查看详情</el-button
          > -->
          </template>
        </el-table-column>

        <el-table-column
          prop="cancelTime"
          label="取消时间"
          width="180"
          align="center"
        >
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { areaCodeData } from "@/util/areaCodeData";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  inject: ["reload"],
  components: { Breadcrumb, Pagination },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "以旧领新",
        },
        {
          name: "已取消订单",
        },
      ], // 面包屑数据
      tableData: [],

      option: [],
      valueArea: "",
      phone: "",
      internationType: false,
      role: "",
      phoneAreaROLE: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.listToCancelOrder,
      method: "POST",
      params: JSON.stringify({
        pageNum: "",
        pageSize: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          let arrList = [];
          res.data.data.list.map((item) => {
            const { orderId } = item.mallOrderInfo;

            let obj = {};
            obj = {
              ...item.addressInfo,
              ...item.boxRenewDTO,
              ...item.goodsInfo,
              ...item.mallOrderInfo,
              ...item.payOrderInfo,
              ...item.skuInfo,
            };
            obj.orderIdcopy = orderId;

            arrList.push(obj);
          });
          this.tableData = arrList;

          arrList.map((item) => {
            if (item.isCancel) {
              item.orderStatus = "已取消";
            } else if (item.isPaid == false) {
              item.orderStatus = "等待买家付款";
            } else if (item.isPaid == true && item.isShip == false) {
              item.orderStatus = "待发货";
            } else if (
              item.isPaid == true &&
              item.isShip == true &&
              item.isConfirm == false
            ) {
              item.orderStatus = "已发货";
            } else if (
              item.isPaid == true &&
              item.isShip == true &&
              item.isConfirm == true
            ) {
              item.orderStatus = "已签收";
            }
            item.cancelTime = new Date(item.cancelTime).toLocaleString();
          });
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.listToCancelOrder,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            let arrList = [];
            res.data.data.list.map((item) => {
              const { orderId } = item.mallOrderInfo;

              let obj = {};
              obj = {
                ...item.addressInfo,
                ...item.boxRenewDTO,
                ...item.goodsInfo,
                ...item.mallOrderInfo,
                ...item.payOrderInfo,
                ...item.skuInfo,
              };
              obj.orderIdcopy = orderId;

              arrList.push(obj);
            });
            this.tableData = arrList;

            arrList.map((item) => {
              if (item.isCancel) {
                item.orderStatus = "已取消";
              } else if (item.isPaid == false) {
                item.orderStatus = "等待买家付款";
              } else if (item.isPaid == true && item.isShip == false) {
                item.orderStatus = "待发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == false
              ) {
                item.orderStatus = "已发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == true
              ) {
                item.orderStatus = "已签收";
              }

              item.cancelTime = new Date(item.cancelTime).toLocaleString();
            });

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.listToCancelOrder,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            let arrList = [];
            res.data.data.list.map((item) => {
              const { orderId } = item.mallOrderInfo;

              let obj = {};
              obj = {
                ...item.addressInfo,
                ...item.boxRenewDTO,
                ...item.goodsInfo,
                ...item.mallOrderInfo,
                ...item.payOrderInfo,
                ...item.skuInfo,
              };
              obj.orderIdcopy = orderId;

              arrList.push(obj);
            });
            this.tableData = arrList;

            arrList.map((item) => {
              if (item.isCancel) {
                item.orderStatus = "已取消";
              } else if (item.isPaid == false) {
                item.orderStatus = "等待买家付款";
              } else if (item.isPaid == true && item.isShip == false) {
                item.orderStatus = "待发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == false
              ) {
                item.orderStatus = "已发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == true
              ) {
                item.orderStatus = "已签收";
              }
              item.cancelTime = new Date(item.cancelTime).toLocaleString();
            });

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    reset() {
      this.phone = "";
      this.phoneArea = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-bottom: 8px;

  .el-input {
    width: 300px;
    margin: 0 10px;
  }
}

::v-deep .el-tabs__content {
  overflow: scroll;
}
</style>
